import React, { useContext } from 'react';

import { DoorsContext } from '../../../../context/DoorsContext';

export default function ScorrevoleEsternaCutListTable() {
    const { doorOrder } = useContext(DoorsContext);

    return (
        doorOrder.doors.map((door, i) => {
            return <div key={i} className='doors-table mt-32'>
                <p>{door.qty} Porte Scorrevoli Esterne - {door.lengthWallHole}cm x {door.heightWallHole}cm</p>
                <div className='header font-bold'>
                    <p>N° Pezzi</p>
                    <p>Articolo</p>
                    <p>Larghezza</p>
                    <p>Altezza</p>
                </div>
                <div className='table-content'>
                    <div className='row'>
                        <p>{door.qty}</p>
                        <p>Pannelli</p>
                        <p>{(door.lengthWallHole - 2.7).toFixed(2)}cm</p>
                        <p></p>
                    </div>
                    <div className='row'>
                        <p>{door.qty}</p>
                        <p>Profili Imbotte</p>
                        <p>{(door.lengthWallHole - 2).toFixed(2)}cm</p>
                        <p></p>
                    </div>
                    <div className='row'>
                        <p>{door.qty * 2}</p>
                        <p>Profili Imbotte</p>
                        <p></p>
                        <p>{(door.heightWallHole - 1).toFixed(2)}cm</p> 
                    </div>
                    <div className='row'>
                        <p>{door.qty * 2}</p>
                        <p>Rinforzi per Imbotte</p>
                        <p></p>
                        <p>{(door.heightWallHole - 12.5).toFixed(2)}cm</p>
                    </div>
                    <div className='row'>
                        <p>{door.qty * 2}</p>
                        <p>Profili U/C</p>
                        <p>{(door.lengthWallHole - 2).toFixed(2)}cm</p>
                        <p></p>
                    </div>
                    <div className='row'>
                        <p>{door.qty * 2}</p>
                        <p>Profili U/C</p>
                        <p></p>
                        <p>{(door.heightWallHole - 1).toFixed(2)}cm</p>
                    </div>
                    <div className='row'>
                        <p>{door.qty}</p>
                        <p>Kit profilo coprifilo</p>
                        <p></p>
                        <p></p>
                    </div>
                    <div className='row'>
                        <p>{door.qty}</p>
                        <p>Arrivo</p>
                        <p></p>
                        <p></p>
                    </div>
                    <div className='row'>
                        <p>{door.qty}</p>
                        <p>Terminale Arrivo</p>
                        <p></p>
                        <p></p>
                    </div>
                </div>
            </div>
        })
    );
}